
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { IUserProfileUpdate, IUserProfile } from "@/interfaces";
import { readUserProfile} from "@/store/main/getters";
import { dispatchUpdateUserProfile } from "@/store/main/actions";
import { dispatchUpdateUser } from "@/store/admin/actions";
import {commitSwitchLanguage} from "@/store/admin/mutations";
import {readLanguage} from "@/store/admin/getters";
import myUpload from 'vue-image-crop-upload/upload-2.vue';
import { api } from "@/api";
import { readToken } from "@/store/main/getters";


@Component({
  components: {
    'my-upload': myUpload
  },
})
export default class UserProfile extends Vue {
  public valid = true;
  public firstname: string = "";
  public lastname: string = "";
  public email: string = "";
  public gender: string = "";
  public road: string = "";
  public house_number: string = "";
  public postcode = 0;
  public city = "";
  public birthplace: string = "";
  public birthday: Date = new Date();
  public nationality: string = "";
  public is_superuser: boolean = false;
  public is_active: boolean = false;
  public items = ["male", "female", "divers"];
  public edit = false;

 //For imageCropUpload----------------
 public showCropper = false;
  public set = false;
  public imgDataUrl: string = '';
  public params = {
    token: '123456789',
    name: 'avatar'
  };
  public headersCropper = {
    smail: '*_~'
  }

  public de = {
        hint: 'Click or drag the file here to upload',
        loading: 'Uploading…',
        noSupported: 'Dieser Browser wird nicht unterstützt, bitte benutzen sie IE10+ oder vergleichbare.',
        success: 'Hochladen erfolgreich',
        fail: 'Hochladen fehlgeschlagen',
        preview: 'Vorschau',
        btn: {
          off: 'Abbrechen',
          close: 'Schließen',
          back: 'Zurück',
          save: 'Sichern'
        },
        error: {
          onlyImg: 'Nur Bilder',
          outOfSize: 'Bild ist zu groß. Limit ist: ',
          lowestPx: 'Bilder Größe ist zu gering. Mindestgröße ist: '
        }
  }

  public en = {
        hint: 'Click or drag the file here to upload',
        loading: 'Uploading…',
        noSupported: 'Browser is not supported, please use IE10+ or other browsers',
        success: 'Upload success',
        fail: 'Upload failed',
        preview: 'Preview',
        btn: {
          off: 'Cancel',
          close: 'Close',
          back: 'Back',
          save: 'Save'
        },
        error: {
          onlyImg: 'Image only',
          outOfSize: 'Image exceeds size limit: ',
          lowestPx: 'Image\'s size is too low. Expected at least: '
        }
  }

  get text() {
    if(this.language) {
      return this.en;
    } else return this.de;
  }

  get hasAdminAccess() {
    const user = readUserProfile(this.$store);
    return user?.is_superuser;
  }
  public toggleShow() {
    this.showCropper = !this.showCropper;
  }

	cropSuccess(imgDataUrl, field){
    this.set = true;
		console.log('-------- crop success --------');
		this.imgDataUrl = imgDataUrl;
	}

  cropUploadSuccess(jsonData, field){
		console.log('-------- upload success --------');
		console.log(jsonData);
		console.log('field: ' + field);
	}

  cropUploadFail(status, field){
				console.log('-------- upload fail --------');
				console.log(status);
				console.log('field: ' + field);
	}

  //Should not be used at the time -> imgDataUrl is already a b64
  public async b64Helper(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  }

  private b64Cleaner(b64:string) {
    let newA: String = b64.replace(
        "dataimage/pngbase64",
        "data:image/png;base64,"
      );
      let newB: String = newA.replace(
        "dataimage/jpegbase64",
        "data:image/jpeg;base64,"
      );
      //Removing padding from backend
      let newC = newB.replace(
        "=",
        ""
      )
      return newC;
  }

  //---------------------------------------


  public mountPic: boolean = false;
  public async created() {
    const userProfile: IUserProfile = readUserProfile(this.$store) as IUserProfile;
    if (userProfile) {var responseb64: string = "";
    await api
      .getProfilePicUser(
        readToken(this.$store),
        userProfile?.id as number
      )
      .then((response) => (responseb64= response.data))
      .catch((error) => console.log(error));
    this.imgDataUrl = this.b64Cleaner(responseb64);
      this.firstname = userProfile.firstname;
      this.lastname = userProfile.lastname;
      this.email = userProfile.email;
      this.birthday = userProfile.birthday;
      this.birthplace = userProfile.birthplace;
      this.gender = userProfile.gender;
      this.city = userProfile.city;
      this.road = userProfile.road;
      if (userProfile.house_number) {
        this.house_number = userProfile.house_number;
      }
      this.postcode = userProfile.postcode;
      this.nationality = userProfile.nationality;
      this.is_superuser = userProfile.is_superuser;
      this.is_active = userProfile.is_active;
    }
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  public reset() {
    if (this.userProfile) {
      this.firstname = this.userProfile.firstname;
      this.lastname = this.userProfile.lastname;
      this.email = this.userProfile.email;
      this.birthday = this.userProfile.birthday;
      this.birthplace = this.userProfile.birthplace;
      this.gender = this.userProfile.gender;
      this.city = this.userProfile.city;
      if (this.userProfile.house_number) {
        this.house_number = this.userProfile.house_number;
      }
      this.road = this.userProfile.road;
      this.postcode = this.userProfile.postcode;
      this.is_superuser = this.userProfile.is_superuser;
      this.is_active = this.userProfile.is_active;
    }
  }
  
  public goToPassword() {
    this.$router.push('/main/profile/password');
  }
  
  public cancel() {
    this.$router.back();
  }

  public numberRules = [
    v => (v > 1000 || v < 1000000) || 'Please enter an valid postcode'
  ];

  //WHEN TIME: sumbit check for every value if it changed
  public async submit() {
      var changed = false;
      const updatedProfile: IUserProfileUpdate = {};
      if (this.userProfile?.firstname != this.firstname) {
        changed = true;
        updatedProfile.firstname = this.firstname;
      }
      if (this.userProfile?.lastname != this.lastname) {
        changed = true;
        updatedProfile.lastname = this.lastname;
      }
      if (this.userProfile?.email != this.email) {
        changed = true;
        updatedProfile.email = this.email;
      }
      if (this.userProfile?.birthday != this.birthday) {
        changed = true;
        updatedProfile.birthday = this.birthday;
      }
      if (this.userProfile?.birthplace != this.birthplace) {
        changed = true;
        updatedProfile.birthplace = this.birthplace;
      }
      if(this.userProfile?.nationality != this.nationality) {
        changed = true;
        updatedProfile.nationality = this.nationality;
      }
      if (this.userProfile?.road != this.road) {
        changed = true;
        updatedProfile.road = this.road;
      }
      if(this.userProfile?.postcode != this.postcode) {
        changed = true;
        updatedProfile.postcode = this.postcode;
      }
      if(this.userProfile?.city != this.city) {
        changed = true;
        updatedProfile.city = this.city;
      }
      if (this.imgDataUrl && this.set) {
        let b64 = this.imgDataUrl;
        try {
          await dispatchUpdateUserProfile(this.$store, {
            id: Number(this.userProfile?.id),
            user: updatedProfile,
            file: b64 as string,
          });
        } catch (e) {

        }
      } else if(changed) {
        await dispatchUpdateUserProfile(this.$store, {
            id: Number(this.userProfile?.id),
            user: updatedProfile,
            file: "null",
        });
      }
      this.$router.push("/main/dashboard");
    }

  get language() {
    return readLanguage(this.$store);
  }
  
  public swtichLanguageEng() {
    console.log("Englisch");
    //True means eng
    if(this.language) {
      commitSwitchLanguage(this.$store);
    }
  }

  public beforeDestroy() {
    if(this.set) {
      this.submit();
    }
  }

  public swtichLanguageDe() {
    //False means de
    console.log("DEUTSCH");
    if(!this.language) {
      commitSwitchLanguage(this.$store);
    }
  }
}
